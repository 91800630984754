import _objectSpread from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { resourceTypeCheckboxType } from "../../assets/js/data";
import { columnDrawer } from "../../data/learn/learn";
import SearchSelect from "../../components/common/Search";
import { uploadFile } from '../../api/qiniuApI';
import EditorU from '../../components/popup/EditorUeditor'; //图文
export default {
  name: "LearnColumn",
  data: function data() {
    return {
      modeType: 'article',
      isEditorShow: false,
      // 图文弹框显示隐藏状态
      ueidtorName: 'ueditor',
      val: '请输入内容',
      editorContent: '',
      isEdit: false,
      //是否编辑
      columnData: {
        //添加结构数据
        name: "",
        //阶段
        logo: "",
        //图片
        columnTypeId: "",
        //类型id
        pid: "",
        //父级ID
        pName: "",
        //父级名称
        rank: 1,
        //排列顺序
        state: 1 //状态：1正常 -1禁用
      },

      //添加结构、修改结构的数据
      columnDrawer: columnDrawer,
      //树形结构数据
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      currentTree: this.$route.query.tree ? JSON.parse(this.$route.query.tree) : {},
      //左边菜单是否折叠
      isCollapse: false,
      //树形结构数据
      treeData: [],
      // 筛选
      filter: [{
        placeholder: '文章状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '文章关键字',
        value: '',
        type: 'text',
        list: []
      }],
      sum: "",
      //类型循环的所有id加起来
      resourceTypeCheckboxType: resourceTypeCheckboxType,
      resourceTypeCheckboxTypeList: _toConsumableArray(resourceTypeCheckboxType.list),
      //类型循环的数据
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: "rtl",
      drawerArticleState: "",
      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false,
      // 删除弹框显示隐藏
      addArticleBtnState: false,
      // 切换栏目弹框
      changeColumnState: false,
      treeChangeList: [],
      defaultTree: {
        children: 'children',
        label: 'name'
      },
      nowArticleData: {},
      columnId: '',
      editPostData: {},
      currentRow: {},
      logoDialogVisible: false
    };
  },
  watch: {
    '$route': 'changeRouter'
  },
  components: {
    SearchSelect: SearchSelect,
    EditorU: EditorU
  },
  created: function created() {
    this.resourcetypeGetAll();
  },
  methods: {
    //路由改变执行的方法
    changeRouter: function changeRouter(json) {
      if (!json) {
        this.currentTree = this.$route.query.tree ? JSON.parse(this.$route.query.tree) : {};
      }
      this.columnData.pId = this.currentTree.id;
      this.filter[1].value = this.$route.query.resName || "";
      this.filter[0].value = this.$route.query.state || "";
      this.filterJson = {
        resName: this.filter[1].value,
        state: this.filter[0].value
      };
      this.initData();
    },
    //给其他组件使用的设置当前的tree
    setCurrentTree: function setCurrentTree(value) {
      this.currentTree = value;
    },
    //获取树状结构
    resourcetypeGetAll: function resourcetypeGetAll() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: 'columnGetAll',
        data: {},
        sucFn: function sucFn(res) {
          // 有所有文章
          if (!_this2.$route.query.tree) {
            var allTree = [{
              id: 0,
              name: "所有文章",
              children: res.data
            }];
            _this2.treeData = allTree;
            _this2.currentTree = _this2.treeData[0];
            _this2.changeRouter(_this2.currentTree);
          } else {
            _this2.treeData = res.data;
            _this2.currentTree = JSON.parse(_this2.$route.query.tree);
            _this2.changeRouter();
          }

          // 没有所有文章
          // this.treeData=res.data;
          // this.currentTree=JSON.parse(this.$route.query.tree);
          // this.changeRouter();

          // 为切换准备的
          _this2.treeChangeList = res.data;
          _this2.$nextTick(function () {
            _this2.$refs.tree.setCurrentKey(_this2.currentTree.id);
          });
        }
      });
    },
    //获取左边的树状结构 - 其他页面的 暂时没用到
    coursestructureGetAll: function coursestructureGetAll(postJson) {
      var _this3 = this;
      var postData = {
        courseId: this.$route.query.courseId
      };
      //如果有搜索字段的话就加上
      if (postJson) {
        postData.structureName = postJson.structureName;
      }
      this.service.axiosUtils({
        requestName: 'coursestructureGetAll',
        data: postData,
        sucFn: function sucFn(res) {
          _this3.treeData = res.data;
          //赋值当前课程信息
          _this3.courseTree = res.data[0];

          //设置下最初的path
          _this3.diskNavData = _this3.courseTree.name + ">" + _this3.courseTree.id;
          if (!_this3.$route.query.tree) {
            _this3.currentTree = _this3.treeData[0];
            _this3.changeRouter(_this3.currentTree);
          } else {
            _this3.currentTree = JSON.parse(_this3.$route.query.tree);
            _this3.changeRouter();
          }
          _this3.$nextTick(function () {
            _this3.$refs.tree.setCurrentKey(_this3.currentTree.id);
          });
        }
      });
    },
    //点击某一个节点
    handleNodeClick: function handleNodeClick(data) {
      this.currentTree = _objectSpread({}, data);
      delete this.currentTree.children;
      var queryData = _objectSpread({}, this.$route.query);
      queryData.tree = JSON.stringify(this.currentTree);
      this.$router.replace({
        path: this.$route.path,
        query: queryData
      });
    },
    //点击展开收起
    isCollapseFn: function isCollapseFn() {
      if (this.isCollapse) {
        this.isCollapse = false;
      } else {
        //展开
        this.isCollapse = true;
      }
    },
    //筛选功能
    search: function search(filterArr) {
      var _this4 = this;
      this.service.axiosUtils({
        requestName: "articleGetList",
        data: {
          title: filterArr[1].value,
          state: filterArr[0].value,
          columnId: this.currentTree.id,
          pageSize: this.pageSize,
          pageIndex: this.currentPage
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this4.tableData = res.data.data;
            _this4.total = res.data.total;
          } else {
            _this4.$message.error(res.message);
          }
        }
      });
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.initData();
    },
    // 添加函数
    addFun: function addFun() {
      this.isEditorShow = true;
    },
    // 初始化数据
    initData: function initData() {
      var _this5 = this;
      /**
       * userId,roleId,pageSize,pageIndex,columnId,title,state
       * **/
      this.service.axiosUtils({
        requestName: "articleGetList",
        data: {
          columnId: this.currentTree.id,
          pageSize: this.pageSize,
          pageIndex: this.currentPage,
          title: '',
          key: ''
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this5.tableData = res.data.data;
            _this5.total = res.data.total;
          } else {
            _this5.$message.error(res.message);
          }
        }
      });
    },
    //新建初始化数据
    clearcolumnData: function clearcolumnData() {
      this.columnData = {
        name: "",
        code: "",
        rank: "",
        state: "",
        type: ""
      };
      this.$refs.form.resetFields();
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this6 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "articleChangeStatearticle",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this6.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this6.$toast.error(res.message);
          }
        }
      });
    },
    // table-中操作函数
    detail: function detail(type, val) {
      if (type === "edit") {
        this.drawerArticleState = "edit";
        this.nowArticleData = val;
        this.isEditorShow = true;
      } else if (type === "del") {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else if (type === 'change') {
        // 切换栏目
        this.changeColumnFun(val);
      } else {}
    },
    confirmClick: function confirmClick(formName) {
      var _this = this;
      if (this.file) {
        uploadFile({
          folderName: "article",
          complete: function complete(res, url) {
            _this.columnData.logo = url;
            _this.addEditColumn(formName);
          }
        }, this.file);
      } else {
        _this.addEditColumn(formName);
      }
    },
    addEditColumn: function addEditColumn(formName) {
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = "";
          if (that.drawerArticleState === "edit") {
            //修改
            url = "columnEditColumn";
          } else {
            //添加
            url = "columnAddColumn";
            that.columnData.pId = that.currentTree.id;
            that.columnData.pName = that.currentTree.name;
          }
          that.service.axiosUtils({
            requestName: url,
            data: that.columnData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.clearcolumnData();
                if (that.drawerArticleState != "edit") {
                  // that.currentPage = 1;
                  //更新树状菜单
                  that.resourcetypeGetAll();
                }
                that.$nextTick(function () {
                  that.$refs["form"].resetFields();
                });
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this7 = this;
      //删除当前的资源类型
      this.service.axiosUtils({
        requestName: "articlesDeletearticle",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this7.$toast.success(res.message);
            _this7.delId = "";
            _this7.centerDialogVisible = false;
            _this7.initData();
          } else {
            _this7.$toast.error(res.message);
          }
        }
      });
    },
    // 切换栏目-显示对应弹框
    changeColumnFun: function changeColumnFun(val) {
      this.nowArticleData = val;
      this.changeColumnState = true;
    },
    // 切换栏目弹框中选择子树栏目
    treeClick: function treeClick(data) {
      this.columnId = data.id;
    },
    // 编辑接口
    EditArticleApi: function EditArticleApi(postData) {
      var _this8 = this;
      /**
       * userId,roleId,id,columnId,title,content,description,rank,state
       * **/
      this.service.axiosUtils({
        requestName: 'articlesEditArticle',
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this8.changeColumnCancel();
            _this8.initData();
          }
        }
      });
    },
    changeColumnCancel: function changeColumnCancel() {
      this.nowArticleData = [];
      this.columnId = '';
      this.editPostData = {};
      this.changeColumnState = false;
    },
    changeColumnConfirm: function changeColumnConfirm() {
      if (!this.columnId) {
        this.$toast.error('没有选择切换的栏目，如无需切换，请取消～');
        return;
      } else {
        this.editPostData = {
          id: this.nowArticleData.id,
          columnId: this.columnId,
          title: this.nowArticleData.title,
          content: this.nowArticleData.content,
          description: '',
          rank: this.nowArticleData.rank,
          state: this.nowArticleData.state
        };
        this.EditArticleApi(this.editPostData);
      }
    },
    //传递完封面的回调函数
    setLogo: function setLogo(cover, row) {
      this.currentRow = row;
      this.currentRow.cover = cover;
      //调用修改封面接口
      this.editArticleCoverFn(cover, row);
    },
    //修改栏目封面
    editArticleCoverFn: function editArticleCoverFn(cover, row) {
      var _this9 = this;
      this.service.axiosUtils({
        requestName: 'editArticleCover',
        data: {
          id: row.id,
          cover: cover
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            if (cover) {
              _this9.$toast.success("添加封面成功");
            } else {
              _this9.logoDialogVisible = false;
              _this9.$toast.success("删除封面成功");
              _this9.initData();
              if (row.state === 1) row.state = -1;else row.state = 1;
            }
          } else {
            _this9.currentRow.cover = "";
            _this9.$toast.error(res.message);
          }
        }
      });
    },
    //删除封面
    deleteLogo: function deleteLogo(row) {
      this.currentRow = row;
      //删除封面
      this.logoDialogVisible = true;
    },
    deleteLogoFn: function deleteLogoFn() {
      var row = this.currentRow;
      this.editArticleCoverFn('', row);
      // this.service.axiosUtils({
      //   requestName: "editArticleCover",
      //   data: {
      //     id: row.id
      //   },
      //   sucFn: (res) => {
      //     if (res.status === 1) {
      //       this.logoDialogVisible=false;
      //       this.$toast.success("删除封面成功");
      //       this.initData();
      //       if (row.state === 1) row.state = -1;
      //       else row.state = 1;
      //     } else {
      //       this.$toast.error(res.message);
      //     }
      //   }
      // })
    }
  }
};